<template>
  <div>
    <transition name="skeleton" mode="out-in">
      <NuxtLink
        v-if="typeof item === 'object'"
        :to="{ name: 'person', params: { person: item.urlkey } }"
        class="flex"
        :class="
          type === 'slider'
            ? 'h-64 md:h-80 shrink-0 max-w-full flex-col p-3 border rounded-lg'
            : ''
        "
      >
        <CommonImageLoader
          class="rounded-full shrink-0 grow-0 mr-3 overflow-hidden"
          :src="item.image"
          :width="64"
          :height="64"
          aspect-ratio="1/1"
          :class="
            type === 'slider'
              ? 'w-16 h-16'
              : placement === 'main'
              ? 'w-12 md:w-18 h-12 md:h-18'
              : 'w-12 h-12'
          "
        />
        <div
          :class="type === 'slider' ? 'flex flex-col h-full items-start' : ''"
        >
          <div
            class="text-black"
            :class="
              type === 'slider'
                ? 'list-title-xs mt-2'
                : placement === 'main'
                ? 'list-title-s'
                : 'list-title-xxxs'
            "
          >
            {{ item.name }}
          </div>
          <div
            class="text-black font-light"
            :class="
              type === 'slider'
                ? 'list-title-xs pr-5'
                : placement === 'main'
                ? 'list-title-s'
                : 'list-title-xxxs'
            "
          >
            <span class="inline-block"
              ><span class="inline mr-2">{{ item.birthdayText }}</span
              ><img
                v-if="type === 'slider' || placement === 'main'"
                :src="birthdayIconUrl"
                class="inline-block w-4 h-4 opacity-35"
            /></span>
          </div>
          <div
            class="opacity-50 text-black font-medium mt-1 max-three-lines"
            :class="type === 'slider' ? 'list-label mt-2' : 'list-label'"
          >
            {{ item.title }}
          </div>
          <template v-if="false">
            <div
              v-if="item.showGreeting && type !== 'slider'"
              class="text-red list-label !font-medium"
              :class="placement === 'main' ? 'mt-4' : 'mt-1'"
            >
              {{ $t('SayCongratulations') }}
            </div>
            <CommonButton
              v-if="item.showGreeting && type === 'slider'"
              class="mt-auto"
              bg="bg-red"
              border="border-none"
              text="text-white"
            >
              {{ $t('SayCongratulations') }}
            </CommonButton>
          </template>
        </div>
      </NuxtLink>
      <ContentLoader
        v-else
        :key="'li' + index"
        :height="placement === 'main' ? 80 : 34"
        :width="placement === 'main' ? 700 : 200"
        :primary-color="store.skeleton.primaryColor"
        :secondary-color="store.skeleton.secondaryColor"
      >
        <template v-if="placement === 'main'">
          <circle cx="36" cy="36" r="36" />
          <rect x="80" y="0" rx="3" ry="3" width="280" height="20" />
          <rect x="80" y="26" rx="3" ry="3" width="210" height="20" />
          <rect x="80" y="52" rx="3" ry="3" width="380" height="10" />
        </template>
        <template v-else>
          <circle cx="17" cy="17" r="17" />
          <rect x="40" y="0" rx="3" ry="3" width="160" height="10" />
          <rect x="40" y="16" rx="3" ry="3" width="150" height="10" />
        </template>
      </ContentLoader>
    </transition>
  </div>
</template>

<script setup lang="ts">
import birthdayIconUrl from '~/assets/icons/birthday.svg?url'
import { ContentLoader } from 'vue-content-loader'
const store = useIndexStore()

defineProps({
  index: {
    type: Number,
    default: 0,
  },
  item: {
    type: [Object, Number],
    default: null,
  },
  type: {
    type: String,
    default: 'default',
  },
  placement: {
    type: String,
    default: 'side',
  },
})
</script>
